import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const useRedirectIfAdmin = (subdomain) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (subdomain === 'admin') {
      navigate('/admin');
    }
  },[])
}