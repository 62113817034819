import React from "react";
import Styles from "./Footer.module.scss";

const Footer = () => {
  return (
    <p className={Styles.footer}>согласование вывесок по всей России</p>
  );
};

export default Footer;
