import React, { useEffect, useState } from "react";
import Styles from "./Services.module.scss";
import { OrangeButton } from "../../elements/orangeButton/button";
import { Heading } from "../../elements/heading/heading";
import ConsultaionModalWindow from "../GetConsultation/GetConsultation";

const Services = ({cityContext, isConsultationOpen, setIsConsultationOpen}) => {

  useEffect(() => {
    isConsultationOpen
      ? (document.body.style.cssText = `
        overflow: hidden;
        padding-right: 10px;
      `)
      : (document.body.style.cssText = `
        overflow: visible;
        padding-right: 0;
      `);
  }, [isConsultationOpen]);

  return (
    <div className={Styles.container}>
      <Heading name="Услуги" extraStyles={{ marginBottom: "15px" }} />
      <p className={Styles.description}>Предлагаем следующие виды услуг</p>
      <p className={Styles.description}>
        Подготовительный этап / Основной этап / Дополнительные услуги
      </p>
      <div className={Styles.servicesContainer}>
        <div className={Styles.serviceBox}>
          <p className={Styles.serviceHeading}>{cityContext ? cityContext[0].heading : "Подготовительный этап:"}</p>
          <p className={Styles.serviceName}>{cityContext ? cityContext[0].subServices[0] : "Подготовка проектно-конструкторской документации"}</p>
          <p className={Styles.serviceName}>{cityContext ? cityContext[0].subServices[1] : "Сбор правоустанавливающих документов на помещение (выписка из ЕГРН, справка БТИ, договор аренды/субаренды помещения)"}</p>
          <p className={Styles.serviceName}>{cityContext ? cityContext[0].subServices[2] : "Подписание дизайн-проекта и Договора на установку и эксплуатацию СНРИ у балансодержателя и арендодателя"}</p>
          <p className={Styles.serviceName}>{cityContext ? cityContext[0].subServices[2] : "Получение согласия собственников помещений многоквартирных жилых домов на размещение СНРИ на фасаде здания"}</p>
        </div>
        <div className={Styles.serviceBoxCenter}>
          <p className={Styles.serviceHeadingCenter}>{cityContext ? cityContext[1].heading : "Разработка и согласование:"}</p>
          <p className={Styles.serviceNameCenter}>{cityContext ? cityContext[1].subServices[0] : "Дизайн-проекта размещения вывески"}</p>
          <p className={Styles.serviceNameCenter}>{cityContext ? cityContext[1].subServices[1] : "Паспорта фасада здания"}</p>
          <p className={Styles.serviceNameCenter}>{cityContext ? cityContext[1].subServices[2] : "Архитектурно-художественной концепции"}</p>
          <p className={Styles.serviceNameCenter}>{cityContext ? cityContext[1].subServices[3] : "Колористического паспорта фасада"}</p>
          <p className={Styles.serviceNameCenter}>{cityContext ? cityContext[1].subServices[4] : "Дизайн-проекта размещения вывески в органах историко-культурного наследия"}</p>
          <p className={Styles.serviceNameCenter}>{cityContext ? cityContext[1].subServices[4] : "Внесения изменений в ранее утвержденные архитектурно-художественные концепции"}</p>
          <p className={Styles.serviceNameCenter}>{cityContext ? cityContext[1].subServices[4] : "Концепции размещения вывесок и рекламы на торговом центре"}</p>
          <p className={Styles.serviceNameCenter}>{cityContext ? cityContext[1].subServices[4] : "Комплексного рекламного оформления"}</p>
        </div>
        <div className={Styles.serviceBox}>
          <p className={Styles.serviceHeading}>{cityContext ? cityContext[2].heading : "Дополнительные услуги:"}</p>
          <p className={Styles.serviceName}>{cityContext ? cityContext[2].subServices[0] : "Представительство в различных организациях городов (Услуга по составлению и подаче запросов, заявлений и получению официальных ответов/предписаний/. Деловая переписка)"}</p>
          <p className={Styles.serviceName}>{cityContext ? cityContext[2].subServices[1] : "Разработка дизайн-проекта размещения вывески с 0"}</p>
        </div>
      </div>
      <div className={Styles.buttonBox}>
        <OrangeButton
          onClickFunction={() => setIsConsultationOpen(!isConsultationOpen)}
          name="Получить консультацию"
          width="393px"
        />
      </div>
      {isConsultationOpen && <ConsultaionModalWindow setIsOpen={setIsConsultationOpen} />}
    </div>
  );
};

export default Services;
