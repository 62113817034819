function importAll(r) {
  let images = {};
  r.keys().forEach((key) => (images[key] = r(key)));
  return images;
}

export const images = importAll(
  require.context('../images/subdomains', true, /\.(jpg|jpeg|png|gif|svg)$/)
);

export const companiesImages = importAll(
  require.context('../images/companies', true, /\.(jpg|jpeg|png|gif|svg)$/)
);

export const emblemImages = importAll(
  require.context('../images/emblems', true, /\.(jpg|jpeg|png|gif|svg|webp)$/)
);