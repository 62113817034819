import Styles from "./loadingSpinner.module.scss";

export const LoadingSpinner = () => {
  return (
    <div className={Styles.ldsDefault}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};
