import React, { useEffect, useMemo } from "react";
import { useFetch } from "../../hooks.js/useFetch";
import Styles from "./Admin.module.scss";
import { Link, NavLink, Route, Routes, useNavigate } from "react-router-dom";
import { LoadingSpinner } from "../../elements/loadingSpinner/loadingSpinner";
import PhotoAttachments from "../../components/Admin/PhotoAttachments";
import Consultations from "../../components/Admin/Consultations";

const Admin = ({setLoggedIn, login, password}) => {
    const currentProtocol = window.location.protocol;
    const body = useMemo(() => ({login, password}), []);

    const { loading, response, error } = useFetch({
        url: `${currentProtocol}//${process.env.REACT_APP_SERVER_URL}forms/`,
        method: "post",
        body
    });
    const navigate = useNavigate();

    useEffect(() => {
        if(!response) return
        navigate({
            pathname: "photos",
        });
    }, [response]);

    useEffect(() => {
        if(!error) return
        setLoggedIn(false)
    }, [error]);

    return (
        <div className={Styles.container}>
            <div className={Styles.header}>
                <NavLink activeclassname={Styles.active} to="photos">
                    Формы с фото
                </NavLink>
                <NavLink activeclassname={Styles.active} to="consultations">
                    Формы с консультациями
                </NavLink>
            </div>
            {loading && (
                <div className={Styles.spinnerBox}>
                    <LoadingSpinner />
                </div>
            )}
            <Routes>
                <Route
                    path="/photos"
                    element={<PhotoAttachments forms={response?.forms} />}
                />
                <Route
                    path="/consultations"
                    element={<Consultations forms={response?.forms} />}
                />
            </Routes>
        </div>
    );
};

export default Admin;
