import { useState } from "react";
import Styles from "./AdminLoginForm.module.scss";
import Admin from "../../routes/Admin/Admin";

export const AdminLoginForm = () => {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [loggedIn, setLoggedIn] = useState(false);

  const handleUsernameChange = (e) => {
    setLogin(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = () => {
      setLoggedIn(true);
  };

  return (
   <>
      {
      loggedIn ?
      <Admin 
         setLoggedIn={setLoggedIn}
         login={login}
         password={password}
      />   
      :
      <div className={Styles.loginForm}>
         <div>
            <h2>АВТОРИЗАЦИЯ</h2>
            <form>
            <label>
               Логин:
               <input
                  type="text"
                  value={login}
                  onChange={handleUsernameChange}
               />
            </label>
            <br />
            <label>
               Пароль:
               <input
                  type="password"
                  value={password}
                  onChange={handlePasswordChange}
               />
            </label>
            <br />
            <button type="button" onClick={handleLogin}>
               Войти
            </button>
            </form>
         </div>
      </div>
      }
   </>
  );
}

export default AdminLoginForm;