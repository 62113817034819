import React, { useEffect, useRef, useState } from "react";
import Selector from "../Selector/Selector";
import Styles from "./Header.module.scss";
import wa from "../../images/wa.svg";
import vk from "../../images/vk.svg";
import tg from "../../images/tg.svg";
import yt from "../../images/yt.svg";
import { useOutsideAlerter } from "../../hooks/useOutsideAlerter";


const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const boxRef = useRef(null);


  return (
    <>

      <div className={Styles.main} ref={boxRef}>
      <h1 className={Styles.heading}><a href="https://po-pravilam.com">ПРАВИЛА ГОРОДА</a></h1>
        <header
          className={`${Styles.header} ${isOpen ? Styles.change_bg : ""}`}
        >
          {/* <Selector fields={["акции", "акции2", "акции3", "акции4"]} /> */}
          <div className={Styles.contactBox}>
            <a href="https://wa.me/79679957664?text=" target="_blank" rel="noreferrer">
              <img src={wa} alt="whats app" />
            </a>
            <a href="https://t.me/+emXEmq3AawY4MGRi" target="_blank" rel="noreferrer">
              <img src={tg} alt="telegram" />
            </a>
            <a href="https://vk.com/pravilagoroda" target="_blank" rel="noreferrer">
              <img src={vk} alt="vkontakte" />
            </a>
            <a href="https://www.youtube.com/channel/UCCqto0m9nq93aSYuySo-2WQ" target="_blank" rel="noreferrer">
              <img src={yt} alt="youtube" />
            </a>
          </div>
          <a className={Styles.phone} href="tel: +74822575505">
          +7 482 257 55 05
          </a>
        </header>
        <div
          onClick={() => setIsOpen(!isOpen)}
          className={`${Styles.menuBtn} ${isOpen ? Styles.open : ""}`}
        >
          <div className={Styles.menuBtn__burger}></div>
        </div>
      </div>
    </>
  );
};

export default Header;
