import React, { useEffect, useState } from "react";
import Styles from "./MainInfo.module.scss";
import { OrangeButton } from "../../elements/orangeButton/button";
import { Heading } from "../../elements/heading/heading";
import PhotoAttachModalWindow from "../AttachPhoto/AttachPhoto.js";
import { useWidthContext } from "../../ContextProviders/WidthProvider";
import { getEmblemImage } from "../../utils/getEmblemImage";

const MainInfo = ({isDescriptionNeeded, heading}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { width } = useWidthContext();
  const emblemImage = getEmblemImage()

  useEffect(() => {
    isOpen
      ? (document.body.style.cssText = `
        overflow: hidden;
        padding-right: 10px;
      `)
      : (document.body.style.cssText = `
        overflow: visible;
        padding-right: 0;
      `);
  }, [isOpen]);

  const descriptionTexts = [
    "Подготовим документы за 5 дней и запустим согласование: чтобы не получить предписание Администрации на демонтаж.",
    "Проконтролируем сроки размещения вывески: напомним, когда срок размещения вашей вывески подойдет к концу.",
    "Работаем по всей России: согласовали 248 вывесок и разработали 172 проекта\nза 2023 год ",
  ];

  return (
    <div className={Styles.box}>
      <div className={Styles.main}>
        <p className={Styles.subHeading}>Согласование вывесок и наружной рекламы по всей России</p>
        <Heading
          name={heading}
          extraStyles={
            width >= 1350 ?
            { width: "900px" } 
            : width <= 500 ? null :
             { width: "550px" } }
        />
        <p className={Styles.description}>Прикрепите фотографию размещения вашей вывески <br />
        и получите консультацию в соответствии  <br /> с правилами благоустройства вашего города</p>
        <img className={Styles.bearMobile} src={emblemImage} alt="" />
        <OrangeButton
          onClickFunction={() => setIsOpen(!isOpen)}
          name="Прикрепить фотографию"
          width="388px"
        />
        <div className={Styles.right}>
          <img src={emblemImage} alt="фото" />
        </div>
      </div>
      {isDescriptionNeeded && 
      <div className={Styles.descriptionContainer}>
        <div className={Styles.descriptionBox}>{descriptionTexts[0]}</div>
        <div className={Styles.descriptionBox}>{descriptionTexts[1]}</div>
        <div className={Styles.descriptionBox}>{descriptionTexts[2]}</div>
      </div>
      }
      {isOpen && <PhotoAttachModalWindow setIsOpen={setIsOpen} />}
    </div>
  );
};

export default MainInfo;
