import { Route, Routes } from "react-router-dom";
import Main from "./routes/Main/Main";
import AdminLoginForm from "./components/AdminLoginForm/AdminLoginForm";
import { useRedirectIfAdmin } from "./utils/useRedirectIfAdmin";

function App() {
    const subdomain = window.location.hostname.split('.')[0];
    useRedirectIfAdmin(subdomain)

    return (
        <Routes>
            {subdomain === 'admin' && (
                <Route path="/admin/*" element={<AdminLoginForm />} />
            )}
            <Route
                path="/*"
                element={<Main/>}
            />
        </Routes>
    );
}

export default App;
