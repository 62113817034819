import React from "react";
import { useDropDownMessageContext } from "../../ContextProviders/DropDownMessageProvider";
import WidthProvider from "../../ContextProviders/WidthProvider";
import Company from "../../components/Company/Company";
import ContactUs from "../../components/ContactUs/ContactUs";
import FAQ from "../../components/FAQ/FAQ";
import Footer from "../../components/Footer/Footer";
import Geography from "../../components/Geography/Geography";
import Header from "../../components/Header/Header";
import MainInfo from "../../components/MainInfo/MainInfo";
import Portfolio from "../../components/Portfolio/Portfolio";
import PriceSheet from "../../components/PriceSheet/PriceSheet";
import Services from "../../components/Services/Services";
import WorkingStages from "../../components/WorkingStages/WorkingStages";
import { DropDownMessage } from "../../elements/dropDownMessage/dropDownMessage";
import { useOpenConsultation } from "../../hooks/useOpenConsultation";


const Main = () => {
    const { isShown } = useDropDownMessageContext();
    const { open: isConsultationOpen, setOpen: setIsConsultationOpen } = useOpenConsultation();



    return (
        <div style={{ overflowX: 'hidden' }}>
            <WidthProvider>
                {isShown && <DropDownMessage />}
                <Header />
                <MainInfo
                    isDescriptionNeeded={true}
                    heading={"Получим для вас разрешение на вывеску\nи наружную рекламу.\nРешим вопросы по претензиям \nи предписаниям от Администрации. \nСрок оказания услуг от 15 рабочих дней"}
                />
               <Services
                  cityContext={undefined}
                  isConsultationOpen={isConsultationOpen}
                  setIsConsultationOpen={setIsConsultationOpen}
               />
               <WorkingStages />
               <Geography />
               <Portfolio />
               <PriceSheet
                  setIsConsultationOpen={setIsConsultationOpen}
               />
               <FAQ />
               <Company />
               <ContactUs />
               <Footer />

         </WidthProvider>
         </div>
   )
}

export default Main