import React from "react";
import Styles from "./FAQ.module.scss";
import { Heading } from "../../elements/heading/heading";
import { FAQSelector } from "../../elements/FAQSelector/FAQSelector";

const FAQ = () => {
  return (
    <div className={`${Styles.container} ${Styles.priceContainer}`}>
      <Heading name="FAQ" />
      <p className={Styles.subHeading}>Часто задаваемые вопросы</p>
      <div className={Styles.informationBox}>
        <FAQSelector
          boldText="Какие штрафы"
          thinText="за несогласованную вывеску?"
          answerText="Убедитесь, что ваша вывеска — информационная. Если она содержит явный или скрытый призыв купить, её признают рекламой. За нарушение ИП штрафуют на сумму от 3000 до 5000 рублей,
          ООО — от 500 000 до 1000 000 рублей по ст. 14.37 КоАП РФ."
        />
        <FAQSelector
          boldText="Какие ограничения по форме и размеру"
          thinText="при согласовании вывески?"
          answerText="Ограничения по форме и размеру при согласовании вывески зависят от законодательства, правил городского планирования и требований архитектурного стиля. Некоторые ограничения включают максимальный размер и высоту вывески, ограничения по цветам и материалам, а также требования к ее расположению и освещению. Конкретные ограничения могут отличаться
          в зависимости от местоположения и типа деятельности организации."
        />
        <FAQSelector
          boldText="Сколько времени"
          thinText="занимает согласование вывески?"
          answerText="Ответ на этот вопрос зависит от местоположения, размера и типа вывески, а также особенностей местных правил и процедур. В разных городах свой порядок согласования вывесок, но в среднем 30 календарных дней. Для точного ответа на этот вопрос найдите ваш город на нашем сайте
          и изучите раздел с вопросами."
        />
        <FAQSelector
          boldText="Какие документы"
          thinText="нужны для согласования вывески?"
          answerText="1. Заявление на согласование вывески с указанием ее характеристик - размеры, цвет, надписи
          и т.д.
          2. Копия документов, подтверждающих право собственности или аренды помещения, на котором будет установлена вывеска.
          3. Графические материалы, которые должны отражать внешний вид и конструкцию вывески.
          4. Проект вывески, разработанный агентством или дизайнером."
        />
      </div>
    </div>
  );
};
export default FAQ;
