import React from "react";
import Styles from "./PriceSheet.module.scss";
import { Heading } from "../../elements/heading/heading";
import { NewLineConverter } from "../../wrappers/NewLineConverter";
import  crocodiles  from "../../images/surprised_crocodile.webp";

const PriceSheet = ({priceList, setIsConsultationOpen}) => {
  const finalPriceList = priceList ? priceList[2] : 'Пролонгация действующего разрешения\n5000 тысяч рублей'


  return (
    <div className={Styles.main}>
      <div className={Styles.container} >
        <Heading name="Прайс-лист" extraStyles={{ marginBottom: '50px'}} />
        <div className={Styles.priceBox}>
          <div>
          <NewLineConverter
            sentence={priceList ? priceList[0] : 'Подготовим пакет документов на одну вывеску за 3000 р, \nа разработку дизайн-проекта рассчитаем индивидуально'}
            class_name={Styles.name}
          />
          </div>
          <button className={Styles.calculateButton} onClick={() => {
            setIsConsultationOpen(true)
          }}>
            Узнать подробнее
          </button>
        </div>
      </div>
      <div className={Styles.line}>
        <NewLineConverter
          sentence={priceList ? priceList[1] : 'Разработаем дизайн-проект, рассчитаем\nв зависимости от типа фасада и вида согласования'}
          class_name={[Styles.name, Styles.innerName]}
        />
        <div className={Styles.lineBox}>
          <button className={Styles.calculateButton} onClick={() => {
            setIsConsultationOpen(true)
          }}>
            Рассчитать стоимость
          </button>
        </div>
        <img src={crocodiles} alt="" />
      </div>
      <div className={`${Styles.container} ${Styles.priceContainer}`}>
        <div className={Styles.priceBox}>
          <div>
            <NewLineConverter
              sentence={finalPriceList}
              class_name={Styles.name}
            />
          </div>
          <button className={Styles.calculateButton} onClick={() => {
            setIsConsultationOpen(true)
          }}>
            Получить консультацию
          </button>
        </div>
      </div>
        <img className={Styles.imageMobile} src={crocodiles} alt="" />
    </div>
  );
};
export default PriceSheet;
