import React from "react";
import Styles from "./WorkingStages.module.scss";
import { Heading } from "../../elements/heading/heading";

const WorkingStages = () => {
  return (
    <div className={Styles.main}>
      <Heading name="Этапы работы" />
      <div className={Styles.pipeLine}>
        <div className={Styles.stageBox}>
          <p className={Styles.number}>1</p>
          <p className={Styles.stageName}>запрашиваем у вас необходимые документы</p>
        </div>
        <div className={Styles.stageBox}>
          <p className={Styles.number}>2</p>
          <p className={Styles.stageName}>
            готовим бланки заявлений и согласий вам на подпись
          </p>
        </div>
        <div className={Styles.stageBox}>
          <p className={Styles.number}>3</p>
          <p className={Styles.stageName}>разрабатываем проект / паспорт / концепцию</p>
        </div>
        <div className={Styles.stageBox}>
          <p className={Styles.number}>4</p>
          <p className={Styles.stageName}>
            подаем заявку (онлайн, почта, порталы, нарочно)
          </p>
        </div>
      </div>
    </div>
  );
};

export default WorkingStages;
