import { emblemImages } from "./loadImages";

export const getEmblemImage = () => {
  let subdomain = window.location.hostname.split('.')[0];
  console.log(subdomain)
  if (subdomain === process.env.REACT_APP_DOMAIN) subdomain = 'main'

  const emblemImageKey = Object.keys(emblemImages).filter(img => img.startsWith(`./${subdomain}`))
  const emblemImage = emblemImages[emblemImageKey]

  return emblemImage
}



