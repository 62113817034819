import axios from "axios";

export const sendConsultationData = (
  data,
  setLoading,
  setMessage,
  setIsShown,
  setIsOpen
) => {
  setLoading(true);
  const currentProtocol = window.location.protocol;

  axios
    .post(`${currentProtocol}//${process.env.REACT_APP_SERVER_URL}forms/consultation`, data, {
      withCredentials: true,
    })
    .then((res) => {
      setMessage(
        "Форма отправлена. В ближайшее время с вами свяжется менеджер."
      );
      setIsShown(true);
    })
    .catch((err) => {
      setMessage("Ошибка отправки формы!");
      setIsShown(true);
    })
    .finally(() => {
      setLoading(false);
      setIsOpen(false);
    });
};
