export const NewLineConverter = ({sentence, class_name}) => {
  let classes = ''
  if(typeof class_name === 'string') {
    classes = class_name
  } else {
    classes = class_name.join(' ')
  }

  return (
    <>
      {
        sentence.split('\n').map((string, i) => (
          <p key={i} className={classes}>
            {string}
            <br/>
          </p>
        ))
      }
    </>
  )
}