const validateDefaultFields = (
   name,
   city,
   phone,
   errors,
) => {
   let hasErrors = false;
    const newErrors = { ...errors };

    if (name === '') {
      newErrors.name = 'Введите ваше имя';
      hasErrors = true;
    } else {
      newErrors.name = '';
    }

    if (city === '') {
      newErrors.city = 'Введите ваш город';
      hasErrors = true;
    } else {
      newErrors.city = '';
    }

    if (phone === '') {
      newErrors.phoneNumber = 'Введите верный номер телефон';
      hasErrors = true;
    } else {
      const digits = phone.replace(/\D/g, '');
      
      if (digits.length !== 10) {
         newErrors.phoneNumber = 'Введите верный номер телефон';
         hasErrors = true;
      } else {
         newErrors.phoneNumber = '';
      }
    }
    return [newErrors, hasErrors]
}

export const validateConsultationForm = (
   name,
   city,
   phone,
   errors,
   setErrors
) => {
   const [newErrors, hasErrors] = validateDefaultFields(
      name,
      city,
      phone,
      errors,
   )

   setErrors(newErrors);
   return !hasErrors;
}

export const validatePhotoAttachmentsForm = (
   name,
   city,
   phone,
   lengthOfAttachments,
   errors,
   setErrors
) => {
   let [newErrors, hasErrors] = validateDefaultFields(
      name,
      city,
      phone,
      errors,
   )

   if (lengthOfAttachments === 0) {
      newErrors.fileAttachments = 'Прикрепите фото';
      hasErrors = true;
    } else {
      newErrors.fileAttachments = '';
    }

   setErrors(newErrors);
   return !hasErrors;
}