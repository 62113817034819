import React, { useEffect, useRef, useState } from "react";
import Styles from "./GetConsultation.module.scss";
import ModalWindow from "../ModalWindow/ModalWindow";
import { OrangeButton } from "../../elements/orangeButton/button";
import { useOutsideAlerter } from "../../hooks/useOutsideAlerter";
import PhoneInput from "../PhoneInput/PhoneInput";
import { CountryCodeSelector } from "../../elements/countryCodeSelector/countryCodeSelector";
import { sendConsultationData } from "../../utils/sendConsultationData";
import { useDropDownMessageContext } from "../../ContextProviders/DropDownMessageProvider";
import { LoadingSpinner } from "../../elements/loadingSpinner/loadingSpinner";
import { validateConsultationForm } from "../../utils/formValidator";
import Pdf from '../../files/pdf/privacy_policy.pdf';

const GetConsultation = ({ setIsOpen }) => {
  const name = useRef();
  const city = useRef();
  const boxRef = useRef(null);
  const [phone, setPhone] = useState("");
  const [errors, setErrors] = useState({
    name: '',
    city: '',
    phoneNumber: ''
  });
  const [loading, setLoading] = useState(false);
  const [countryCode, setCountryCode] = useState("+7");
  const handleInput = ({ target: { value } }) => setPhone(value);
  const { setMessage, setIsShown } = useDropDownMessageContext();
  useOutsideAlerter(boxRef, () => setIsOpen(false));

  const sendData = () => {
    if(!validateConsultationForm(
      name.current.value,
      city.current.value,
      phone,
      errors,
      setErrors
    )) return

    sendConsultationData(
      {
      name: name.current.value,
        city: city.current.value,
        phoneNumber: `${countryCode}${phone}`,
      },
      setLoading,
      setMessage,
      setIsShown,
      setIsOpen
    );
  };
  
  return (
    <div className={Styles.main} ref={boxRef}>
      <h2>Консультация</h2>
      <p className={Styles.subtext}>
        Оставьте ваши данные и мы свяжемся с&nbsp;вами в течении часа
      </p>
      <p className={Styles.fieldName}>
        {errors.name !== "" ? <span className={Styles.errorMessage}>{errors.name}</span> : <>Как вас зовут?<span>*</span></>}
      </p>
      <input ref={name} type="text" placeholder="Иван" />
      <p className={Styles.fieldName}>
        {errors.city !== "" ? <span className={Styles.errorMessage}>{errors.city}</span> : <>Из какого вы города?<span>*</span></>}
      </p>
      <input ref={city} type="text" placeholder="Москва" />
      <p className={Styles.fieldName}>
        {errors.phoneNumber !== "" ? <span className={Styles.errorMessage}>{errors.phoneNumber}</span> : <>Введите ваш номер телефона<span>*</span></>}
      </p>
      <div className={Styles.phoneBox}>
        <CountryCodeSelector
          countryCode={countryCode}
          setCountryCode={setCountryCode}
        />
        <PhoneInput value={phone} onChange={handleInput}></PhoneInput>
      </div>
      <p className={Styles.subtext}>
      Отправляя, вы соглашаетесь <a href= {Pdf} target="_blank" rel="noreferrer">
        с&nbsp;политикой конфиденциальности
            </a>
      </p>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <OrangeButton
          name="Отправить"
          width="275px"
          onClickFunction={() => sendData()}
        />
      )}
    </div>
  );
};

const ConsultaionModalWindow = ({ setIsOpen }) => {
  return (
    <ModalWindow>
      <GetConsultation setIsOpen={setIsOpen} />
    </ModalWindow>
  );
};

export default ConsultaionModalWindow;
